import { Controller } from 'stimulus'
import throttle from 'lodash/throttle'
import { styles_list } from 'shared/assets/javascript/google_map_silver_styles'

export default class extends Controller {
  static targets = ['map']

  get apiKey() {
    return this.element.dataset.googleMapsApiKey
  }

  get scriptAppended() {
    return !!document.querySelector('script[src*="https://maps.googleapis.com/maps/api/"]')
  }

  get mapInView() {
    if (!this.mapTarget) return
    let mapTop = this.mapTarget.offsetTop + 150
    let windowTop = window.scrollY
    let windowBottom = window.scrollY + window.innerHeight
    return mapTop <= windowBottom && mapTop >= windowTop
  }

  get setIconSize() {
    // FIXME
    // if (micro_global.is_clubroom())
    //   return new google.maps.Size(75, 135)
    // else if (micro_global.is_cinema())
    //   return new google.maps.Size(39, 65)
    // else if (micro_global.is_django())
    //   return new google.maps.Size(27, 40)
    // else
    //   return new google.maps.Size(40, 40)
    return new google.maps.Size(75, 135)
  }

  load() {
    if (this.scriptAppended) {
      this.buildMap()
    } else {
      window.buildMap = this.buildMap.bind(this)
      this.appendScript('buildMap')
    }
  }

  appendScript(callback_string) {
    let googleMapScript = document.createElement('script')
    googleMapScript.type = 'text/javascript'
    googleMapScript.setAttribute('async', '')
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&libraries=places,geometry&callback=${callback_string}`
    document.body.appendChild(googleMapScript)
  }

  buildMap() {
    this.map = new google.maps.Map(this.mapTarget, {
      disableDefaultUI: true,
      draggable: true,
      scrollable: false,
      mapTypeControl: false,
      streetViewControl: false,
      zoom: 14.7
    })

    this.map.setOptions({ styles: styles_list })

    // Check if map is in view so marker animation only occurs once and in-view
    if (this.mapInView) {
      this.createMarker()
    } else {
      this.pinCreated = false
      window.addEventListener('scroll', throttle(this.scroll.bind(this), 250))
    }

    // Change map zoom on window resize
    this.mapZoom()
    window.addEventListener('resize', throttle(this.mapZoom.bind(this), 250))
  }

  scroll() {
    if (this.mapInView && !this.pinCreated) {
      this.createMarker()
      this.pinCreated = true
    }
  }

  createMarker() {
    let data = this.mapTarget.dataset
    let lat = parseFloat(data.lat)
    let lng = parseFloat(data.lng)
    let options = {
      icon: {
        url: data.icon,
        scaledSize: this.setIconSize
      },
      position: {
        lat: lat,
        lng: lng,
      },
      animation: google.maps.Animation.DROP,
      title: 'Location Icon',
      map: this.map
    }
    new google.maps.Marker(options)
    let center = new google.maps.LatLng(lat, lng)
    this.map.setCenter(center)
  }

  mapZoom() {
    // FIXME
    // if (micro_global.is_gilligans())
    //   if window_width < 1280 && window_width > 699
    //     map.setZoom(16)
    //   else if window_width < 700
    //     map.setZoom(15.7)
    //   else
    //     map.setZoom(16.7)
    let windowWidth = window.outerWidth
    if (windowWidth < 1280 && windowWidth > 699) {
      this.map.setZoom(14)
    } else if (windowWidth < 700) {
      this.map.setZoom(13.2)
    } else {
      this.map.setZoom(14.7)
    }
  }
}
